<template>
  <v-container class="mt-3 px-sm-10 px-0 elevation-0" fluid>
    <PageHeader
      class="mb-7"
    >
      <template #header>
        <img
          src="@/assets/plugins/lahlouh_plugin.svg"
          alt="lahlouh logo"
          height="32"
        >
        Lahlouh Export
      </template>
      <template #subheader>
        Request an export for Lahlouh and view your active and previous exports
      </template>
    </PageHeader>

    <v-row class="flex-nowrap">
      <v-col cols="12">
        <div class="pb-0">
          This export will return a list of all contacts currently "on hold" in a campaign. If an automated export has
          been configured for this plugin already, it's possible they have already been sent to Lahlouh. Automated
          exports are recommended instead of manual exports. We only recommend manual exports in cases where we need
          more information on what's coming up.
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-start flex-wrap pt-2">
        <v-btn
          class="custom-button custom-button--blue px-11 mr-sm-5 mb-4 mb-sm-0"
          style="min-width: 150px"
          height="34px"
          depressed
          :block="$vuetify.breakpoint.xsOnly"
          @click="exportDialog = true"
        >
          Create Export
        </v-btn>

        <v-dialog
          v-model="exportDialog"
          scrollable
          max-width="560px"
        >
          <ExportDialog
            v-if="exportDialog"
            header="Export Contacts"
            :fields-to-export="exportFields"
            @export="onExport"
            @dismiss="exportDialog = false;"
          >
            <template #top>
              <div class="mb-6" style="color: #000;">
                Exporting contacts for Lahlouh
              </div>
            </template>
          </ExportDialog>
        </v-dialog>
      </v-col>
    </v-row>

    <div v-if="currentExports.length" class="field__header mt-10">
      Active Exports
    </div>
    <ExportsTable
      v-if="currentExports.length"
      :exports="currentExports"
      :for-previous-exports="true"
    />

    <div class="field__header mt-10">
      Previous Exports
    </div>
    <ExportsTable
      :exports="previousExports"
    />
    <PaginationButtons
      :current-page-number="exportsPageNumber"
      :current-page-size="exportsPageSize"
      :count-total="exportsTotal"
      :page-sizes="[25, 50, 100]"
      @next-page="getExportsNextPage"
      @prev-page="getExportsPrevPage"
      @change-page-size="exportsChangePageSize"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import ExportsTable from "@/views/Exports/components/ExportsTable";
import ExportDialog from "@/sharedComponents/ExportDialog";
import PaginationButtons from "@/sharedComponents/pagination/PaginationButtons";

export default {
  name: "LahlouhExportsView",
  metaInfo: {
    title: 'Lahlouh Exports'
  },
  components: {
    PageHeader,
    ExportsTable,
    ExportDialog,
    PaginationButtons,
  },
  data() {
    return {
      currentExports: [],
      previousExports: [],
      eventSource: null,
      completedStatuses: ['completed', 'canceled', 'failed'],
      previousStatuses: ['queued', 'preparing', 'analyzing', 'requires action', 'processing', 'finalizing'],
      exportDialog: false,
      exportFields: [],
      exportsPageNumber: 1,
      exportsPageSize: 25,
      exportsTotal: 0,
      exportType: 'lahlouh_contact',
    };
  },
  async created() {
    await this.getPreviousExports();
    await this.getCurrentExports();
    await this.getExportFields();

    this.eventSource = await this.$store.dispatch('mercure/export_type_subscribe', this.exportType);
    if (!this.eventSource) {
      return;
    }

    this.eventSource.onmessage = (msg) => {
      this.$store.dispatch('mercure/update_export_type_last_event', {
        exportType: this.exportType,
        lastEventId: msg.lastEventId
      });

      const parsedMessage = JSON.parse(msg.data);

      const currentExportIndex = this.currentExports.findIndex(elem => elem.id === parsedMessage.id) ?? null;
      if (currentExportIndex === -1) {
        this.currentExports.unshift(parsedMessage);
        return;
      }

      if (this.completedStatuses.includes(parsedMessage.status)) {
        this.$delete(this.currentExports, currentExportIndex);
        this.getPreviousExports();

        this.$store.commit('snackbar/showMessage', {
          color: "success",
          content: `An export has completed. Please check the section below for the results.`
        })
        return;
      }

      this.$set(this.currentExports, currentExportIndex, parsedMessage);
    };
  },
  beforeDestroy() {
    if (!this.eventSource) {
      return;
    }
    this.$store.dispatch('mercure/unsubscribe', this.eventSource)
  },
  methods: {
    async getExportsNextPage() {
      this.exportsPageNumber += 1;
      await this.getPreviousExports();
    },
    async getExportsPrevPage() {
      this.exportsPageNumber -= 1;
      await this.getPreviousExports();
    },
    async exportsChangePageSize(size) {
      this.exportsPageSize = size;
      this.exportsPageNumber = 1;
      await this.getPreviousExports();
    },
    async getPreviousExports() {
      const resp = await this.$rest.exports.get_collection({
        limit: this.exportsPageSize,
        page: this.exportsPageNumber,
        exportType: this.exportType,
        status: this.completedStatuses,
        sort: ['updatedAt:desc'],
      });
      this.previousExports = resp.data.items;
      this.exportsTotal = resp.data.totalCount;
    },
    async getCurrentExports() {
      const resp = await this.$rest.exports.get_collection({
        exportType: this.exportType,
        status: this.previousStatuses,
        sort: ['createdAt:desc'],
      });
      this.currentExports = resp.data.items;
    },
    async getExportFields() {
      this.exportFields = (await this.$rest.exports.get_export_type(this.exportType)).data?.headers ?? [];
    },
    async onExport(details) {
      await this.$rest.exports.post_resource({
        "exportType": this.exportType,
        "exportData": {
          ...details
        },
      })

      await this.getCurrentExports();

      this.exportDialog = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.mobile-hint {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  color: #66788e;
}

.field {
  &__header {
    margin-bottom: 25px;
  }
}
</style>
